.banner{
    padding:0;
    margin:0;
    background-color:#f0ead2;
    height: 70vh;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 1;
}
@keyframes slidein {
    0% {margin-top:1rem; opacity: 0;}
    100% {margin-top:0; opacity: 1;}
}
.banner .title-container{
    margin:0;
    padding: 0;
    align-self: center;
    animation-name: slidein;
    animation-duration: 0.9s;
}
.banner .title-container .title{
    margin:0;
    padding: 0;
    font-family: 'Libre Baskerville', serif;
    font-weight: 600;
    color: #6c584c;
    font-size: 90px;
}
.banner .title-container .description{
    font-family: 'Montserrat', serif;
    font-size: 36px;
    color: #a98467;
}
.specs{
    font-family: 'Montserrat', serif;
    font-size: 16px;
    color: #6c584c;
}
@media (max-width: 1100px) {
    .banner .title-container .title{
        font-size: 50px;
    }
    .banner .title-container .description{
        font-size: 20px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .specs{
        font-size: 12px;
    }
    .donate-banner{
        display: none;
    }
}

