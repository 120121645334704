.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: rgba(226, 218, 201, 1);
    backdrop-filter: saturate(100%) blur(20px);
    align-items: center;
    position: sticky;
    transition: 0.4s;
    top: 0;
    z-index: 9999;
}
.mobLogo{
    display: none;
}
.interestmob{
    display: none;
}
.interestweb{
    font-size: 13px;
    font-family: 'Montserrat', serif;
    text-align: center;
    color: #6c584c;
}
.cover{
    position: sticky;
}
.navbar a{
    font-family: 'Montserrat', serif;
    text-decoration: none;
    cursor: pointer;
    color: #7ba059;
    font-weight: 600;
}
.navbar .logoContainer{
    width: 400px;
}
.navbar .logoLink{
    padding: 0;
    margin: 0 2.5rem 0;
}
.navbar .logoLink .logo{
    width: 8.502rem;
    height: 6rem;
}
.navbar .links{ 
    padding:0;
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.navbar .links .sectionLink{
    text-decoration: none;
    margin-right: 2.5rem;
}
.navbar .title-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.navbar .title{
    font-family: 'Libre Baskerville', serif;
    color:#7ba059;
    transition: 0.5s;
}
.mob-donate{
    display: none;
}

@media (max-width: 1100px) {
    .navbar{
        flex-direction: column;
    }
    .logoContainer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logoContainer .mobLogo{
        display: block;
        width: 50px;
    }
    .logoContainer .logo{
        display: none;
    }
    .navbar .title{
        display: none;
    }
    .navbar .links{
        width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .navbar .links .sectionLink{
        text-decoration: none;
        margin-bottom: 1rem;   
    }
    .navbar .logoLink{
        padding: 0;
        margin: 0 0.5rem 0;
    }
    .navbar .donate-container{
        display: none;
    }
    .mob-donate{
        display: block;
        margin-bottom: 20px;
    }
}

