/* Container */
.container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.carousel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border: 1px solid green;
    border-radius: 24px;
    width: 100%;
    height: 80%;
    /* This padding is taken into account for item width */
  }
  
  /* Track */
  .carousel-track {
    display: flex;
  }
  
  /* Carousel item */
  .carousel-item {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #555;
    border-radius: calc(var(--outer-r) - var(--p-distance));
    background-color: #0D0D0D;
    overflow: hidden;
    cursor: grab;
  }
  
  .carousel-item:active {
    cursor: grabbing;
  }
  
  .carousel-container.round {
    border: 1px solid #555;
  }
  
  .carousel-item.round {
    background-color: #0D0D0D;
    position: relative;
    bottom: .1em;
    border: 1px solid #555;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .carousel-item-header.round {
    padding: 0;
    margin: 0;
  }
  
  .carousel-indicators-container.round {
    position: absolute;
    z-index: 2;
    bottom: 3em;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carousel-indicator.active {
    background-color: #333333;
  }
  
  .carousel-indicator.inactive {
    background-color: rgba(51, 51, 51, 0.4);
  }
  
  .carousel-item-header {
    margin-bottom: 16px;
    padding: 20px;
    padding-top: 20px;
  }
  
  .carousel-icon-container {
    display: flex;
    height: 28px;
    width: 28px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .carousel-icon {
    height: 16px;
    width: 16px;
    color: #060606;
  }
  
  .carousel-item-content {
    padding: 20px;
    padding-bottom: 20px;
  }
  
  .carousel-item-title {
    margin-bottom: 4px;
    font-weight: 900;
    font-size: 18px;
    color: #fff;
  }
  
  .carousel-item-description {
    font-size: 14px;
    color: #fff;
  }
  
  /* Indicators */
  .carousel-indicators-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .carousel-indicators {
    margin-top: 16px;
    display: flex;
    width: 150px;
    justify-content: space-between;
    padding: 0 32px;
  }
  
  .carousel-indicator {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 150ms;
  }
  
  .carousel-indicator.active {
    background-color: green;
  }
  
  .carousel-indicator.inactive {
    background-color: #555;
  }